const actions = {

    CREATE_MAINTENANCE_REQUEST:'CREATE_MAINTENANCE_REQUEST',
    FETCH_MAINTENANCE_LIST_REQUEST:'FETCH_MAINTENANCE_LIST_REQUEST',
    FETCH_MAINTENANCE_LIST_SUCCESS:"FETCH_MAINTENANCE_LIST_SUCCESS",
    FETCH_MAINTENANCE_LIST_FAIL:"FETCH_MAINTENANCE_LIST_FAIL",

    RETRIEVE_MAINTENANCE_REQUEST:"RETRIEVE_MAINTENANCE_REQUEST",
    UPDATE_MAINTENANCE_REQUEST:"UPDATE_MAINTENANCE_REQUEST",

    DELETE_MAINTENANCE_REQUEST:"DELETE_MAINTENANCE_REQUEST",

    CREATE_SERVICE_REQUEST:"CREATE_SERVICE_REQUEST",
    RETRIEVE_SERVICE_REQUEST:"RETRIEVE_SERVICE_REQUEST",

    FETCH_SERVICE_LIST_REQUEST:"FETCH_SERVICE_LIST_REQUEST",
    FETCH_SERVICE_LIST_SUCCESS:"FETCH_SERVICE_LIST_SUCCESS",
    FETCH_SERVICE_LIST_FAIL:"FETCH_SERVICE_LIST_FAIL",

    createMaintenance:(data,context,resolve,reject) => ({
        type:actions.CREATE_MAINTENANCE_REQUEST,
        data,
        context,
        resolve,
        reject
    }),
     updateMaintenance: (id, data, resolve,reject) => ({
        type: actions.UPDATE_MAINTENANCE_REQUEST,
        id,
        data,
        resolve,
        reject
    }),
    retrieveMaintenance: (id,resolve,reject) => {
      return{
      type: actions.RETRIEVE_MAINTENANCE_REQUEST,
      id,
      resolve,
      reject
    }},
     deleteMaintenance: (id,resolve,reject) => ({
      type: actions.DELETE_MAINTENANCE_REQUEST,
      id,
      resolve,
      reject
    }),
    createService:(id,data,context,resolve,reject) => ({
        type:actions.CREATE_SERVICE_REQUEST,
        id,
        data,
        context,
        resolve,
        reject
    }),
    retrieveService: (id,resolve,reject) => {
      return{
      type: actions.RETRIEVE_SERVICE_REQUEST,
      id,
      resolve,
      reject
    }},
     fetchServiceList:(resolve, reject) => ({
        type:actions.FETCH_SERVICE_LIST_REQUEST,
        resolve,
        reject
    })
};
export default actions;
