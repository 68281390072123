const actions = {
    LIST_TRAINING_REQUEST: "LIST_TRAINING_REQUEST",
    RETRIEVE_TRAINING_REQUEST: "RETRIEVE_TRAINING_REQUEST",

  
    fetchTrainingList: (resolve, reject) => ({
      type: actions.LIST_TRAINING_REQUEST,
      resolve,
      reject,
    }),
    retrieveTraining: (id, resolve, reject) => ({
      type: actions.RETRIEVE_TRAINING_REQUEST,
      id,
      resolve,
      reject,
    })
  };
  export default actions;
  