import React, { Component } from "react";
import { message } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import Auth0 from "Common/helpers/auth0-custom/index";
import LayoutContent from "Common/components/layoutContent";
import LayoutWrapper from "Common/components/layoutWrapper.js";
import IntlMessages from "Common/components/intlMessages";
import { UserSection } from "Common/components/Table/styles/responsiveTableWrapper.style";
import ItemSummary from "Common/components/ItemSummary";
import ItemStatus from "Common/components/ItemSummary/status";
import ItemTag from "Common/components/ItemSummary/tag";
import PageHeader from "Common/components/Page/PageHeader";
import TableWrapper from "Common/components/Table/index";
import Protected from "Common/components/authorization/Protected";
import getProtectedColumn from "Common/components/authorization/ProtectedColumn";

import UserAdvancedFilterForm from "User/components/form/UserAdvancedFilterForm";

import actions from "User/redux/actions";
import actionPublic from "Public/redux/actions";

const auth0 = new Auth0();

const sorter = (a, b) => ("" + a.attr).localeCompare(b.attr);

const breadcrumbs = [
  {
    title: "Dashboard",
    path: "/dashboard"
  },
  {
    title: "Users"
  }
];

const defaultQuickFilter = {
  filterBy: "role",
  defaultValue: "all",
  options: [
    {
      tab: "All Users",
      key: "all"
    }
  ]
};

const quickFilter = (roles) => {
  let newQuickFilterOptions = defaultQuickFilter["options"];
  for (let role in roles) {
    if (auth0.userHasScope("read:users:" + roles[role].name) && role < 3) {
      newQuickFilterOptions = newQuickFilterOptions.concat({
        tab: roles[role].description,
        key: roles[role].name
      });
    }
  }
  return {
    ...defaultQuickFilter,
    options: newQuickFilterOptions
  };
};

const { fetchUserDetail, deleteUserDetail, userDetailEdit } = actions;
const { fetchRole } = actionPublic;

class ListUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updatedOn: moment().valueOf()
    };
  }

  handleRowClick = (record) => {
    this.props.history.push("/users/" + record.id);
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      subType: nextProps.match.params.type
    });
  };

  render() {
    return (
      <LayoutWrapper>
        <PageHeader
          title={<IntlMessages id="pagetitle.listuser" />}
          breadcrumbs={breadcrumbs}
        >
          <Protected scope="write:users">
            <p></p>
          </Protected>
        </PageHeader>
        <LayoutContent loading={this.state.loading}>
          <UserSection>
            <TableWrapper
              defaultQuickFilterValue={this.props.match.params.type}
              history={this.props.history}
              handleRowClick={this.handleRowClick}
              updatedOn={this.state.updatedOn}
              columns={this.getColumns()}
              url="user"
              advancedFilterForm={UserAdvancedFilterForm}
              quickFilterElements={quickFilter(this.props.roles)}
              quickFilterValue={this.props.defaultQuickFilterValue}
              quickFilterStatus={this.props.match.params.type}
              createTitle="Create User"
              onClick={this.onCreateClicked}
              defaultSort={{field: 'firstName', order: 'ascend'}}
              tmsTable
            />
          </UserSection>
        </LayoutContent>
      </LayoutWrapper>
    );
  }

  onCreateClicked = (e) => {
    e.preventDefault();
    this.props.history.push("/users/create");
  };

  onDetailClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/users/" + id);
  };

  onDeleteClicked = (id) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.deleteUserDetail(id, resolve, reject);
    })
      .then(() => {
        this.setState({ loading: false, updatedOn: moment().valueOf() });
        message.success("Successfully deleted user");
      })
      .catch((error) => {
        message.error("Error while deleting user");
      }, this);
  };

  onEditClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/users/" + id + "/edit/");
  };

  confirm = (e) => {
    var self = this;
    self.onDeleteClicked(e);
  };

  onSuspend = (data) => {
    const { contact, email, firstName, lastName, phoneNumber, role, id } = data;
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.userDetailEdit(
        {
          contact,
          email,
          firstName,
          lastName,
          phoneNumber,
          role,
          status: "suspended"
        },
        id,
        resolve,
        reject
      );
    })
      .then(() => {
        this.setState({ loading: false, updatedOn: moment().valueOf() });
        message.success("Successfully suspended user.");
      })
      .catch((error) => {
        message.error("Error while suspending user.");
      }, this);
  };

  getDefaultColumns = () => {
    const self = this;
    return [
      {
        title: "S.N",
        key: "listNumber",
        render: (text, row, index) => {
          return <span>{ index + 1 }</span>;
        }
      },
      {
        title: "Full Name",
        key: "firstName",
        sorter: { sorter },
        render: (text, row, index) => {
          return (
            <a
              href={`/users/${row.id}`}
              onClick={self.onDetailClicked.bind(this, row.id)}
            >
              <ItemSummary
                avatar={""}
                title={row.firstName + " " + row.lastName}
                subtitle={row.email}
              />
            </a>
          );
        }
      },
      {
        title: "Company",
        dataIndex: "contact.firstName",
        key: "contact",
        sorter: { sorter },
      },
      {
        title: "Phone",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        render: (phoneNumber) => (
          <span className="phonenumber">{phoneNumber}</span>
        )
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: { sorter },
        render: (status) => <ItemStatus status={status} />
      },
      {
        title: "User Type",
        dataIndex: "role",
        key: "role",
        sorter: { sorter },
        render: (text) => <ItemTag type="usertype" value={text} />
      }
    ];
  };

  getColumns = () => {
    return getProtectedColumn(
      this.getDefaultColumns,
      this.onEditClicked,
      this.confirm,
      "users",
      this.onSuspend
    );
  };
}

export default connect(
  (state) => ({
    roles: state.Auth.roles_write
  }),
  { fetchUserDetail, deleteUserDetail, fetchRole, userDetailEdit }
)(ListUser);
