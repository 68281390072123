import { all } from "redux-saga/effects";
import authSagas from "Public/redux/saga";
import userSagas from "User/redux/saga";
import locationSagas from "Location/redux/saga";
import driverSagas from "Driver/redux/saga";
import driverSignupSagas from "DriverSignup/redux/saga";
import vehicleSagas from "Vehicle/redux/saga";
import bookingSagas from "Booking/redux/saga";
import allocationSagas from "Allocation/redux/saga";
import calendarSagas from "Common/components/Calendar/redux/saga";
import dropdownSagas from "Dropdown/redux/saga";
import contactSagas from "Contact/redux/saga";
import trackingSagas from "Tracking/redux/saga";
import passengerSagas from "Passenger/redux/saga";
import incidentSagas from "Incident/redux/saga";
import feedbackSagas from "Feedback/redux/saga";
import routeSagas from "Route/redux/saga";
import runSagas from "Run/redux/saga";
import dashboardSagas from "Dashboard/redux/saga";
import fatigueSagas from "Fatigue/redux/saga";
import faultSagas from "Fault/redux/saga";
import ncrSagas from "NCR/redux/saga";
import fuelSagas from "Fuel/redux/saga";
import supplierSaga from "Supplier/redux/saga";
import maintenanceSagas from "Maintenance/redux/saga";
import assetsSagas from "Assets/redux/saga";
import assetTypeSagas from "AssetType/redux/saga";
import menuSagas from "App/redux/saga";
import documentSagas from "Document/redux/saga";
import toolboxSagas from "Toolbox/redux/saga";
import shiftsSagas from "Shifts/redux/saga";
import newsTrainingSagas from "NewsTraining/redux/saga";
import noticeSagas from "NoticeBoard/redux/saga";
import notificationSagas from "Notification/redux/saga";
import ewdSagas from "EWD/redux/saga";
import trainingSagas from "Training/redux/saga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    userSagas(),
    locationSagas(),
    driverSagas(),
    vehicleSagas(),
    bookingSagas(),
    allocationSagas(),
    calendarSagas(),
    dropdownSagas(),
    contactSagas(),
    trackingSagas(),
    passengerSagas(),
    incidentSagas(),
    feedbackSagas(),
    routeSagas(),
    runSagas(),
    dashboardSagas(),
    fatigueSagas(),
    faultSagas(),
    ncrSagas(),
    fuelSagas(),
    supplierSaga(),
    maintenanceSagas(),
    assetsSagas(),
    assetTypeSagas(),
    menuSagas(),
    driverSignupSagas(),
    documentSagas(),
    toolboxSagas(),
    shiftsSagas(),
    newsTrainingSagas(),
    noticeSagas(),
    notificationSagas(),
    ewdSagas(),
    trainingSagas(),
  ]);
}
