import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Api from 'Common/api/Api2';

const api = new Api();

export function* fetchDocumentList() {
    yield takeEvery(
      actions.FETCH_DOCUMENT_LIST_REQUEST,
      function* ({ resolve, reject }) {
        try {
          const response = yield call(api.get, "documents?pageSize=1000", {});
          if (resolve) {
            yield call(resolve, response);
          } else {
            yield put({
              type: actions.FETCH_DOCUMENT_LIST_SUCCESS,
              response: response.data.results,
            });
          }
        } catch (error) {
          if (reject) {
            yield call(reject, error);
          } else {
            yield put({
              type: actions.FETCH_DOCUMENT_LIST_FAIL,
              error,
            });
          }
        }
      }
    );
  }

export default function* rootSaga(){
  yield all([
    fork(fetchDocumentList),
  ]);
}

