import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Alert } from 'antd';
import { Field, FormSection } from 'redux-form';
import Auth0 from 'Common/helpers/auth0-custom/index';
import Select, { SelectOption } from 'Common/components/select';
import { ContactName } from 'Contact/settings/contactType';
import SelectFieldWrapper from 'Common/styles/selectfield.style';
import actionsUser from "User/redux/actions";
import actions from "Contact/redux/actions";

const { listContact } = actions;
const { fetchUserDetail } = actionsUser;
const auth0 = new Auth0();

class ContactSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      entity: {},
      selectedContactId: props.value || ""
    };
  }

  componentDidMount() {
    // Fetch contact data if needed
    if (!this.props.data?.length && !this.props.loading && auth0.userHasScope("read:contacts")) {
      this.props.listContact();
    }
  
    // Fetch user details
    new Promise((resolve, reject) => {
      this.props.fetchUserDetail(auth0.getCurrentUserId(), resolve, reject);
    }).then((response) => {
      const userContactId = response.data.contact?.id || ""; // Get operator's company ID
      this.setState({
        loading: false,
        entity: response.data,
        selectedContactId: this.props.value || this.props.input?.value || userContactId,
      });
  
      // If `redux-form` input exists, sync with its value
      if (this.props.input?.onChange && !this.props.input.value) {
        this.props.input.onChange(userContactId);
      }
    }).catch((error) => {
      this.setState({ loading: false });
    });
  }
  

  getContactListByContext = () => {
    const { types, data } = this.props;
    const formatTypes = types && types.map((type) =>
      type === "CLIENTINDIVIDUAL" ? "client individual" : type.toLowerCase()
    ) || [];

    const activeContact = data.filter(contact => contact.status === "active");

    if (auth0.userHasScope("context:system")) {
      return types ? activeContact.filter(contact => formatTypes.includes(contact.contactType.title)) : activeContact;
    } else {
      return [this.state.entity.contact];
    }
  }

  handleSelectChange = (value) => {
    // If `redux-form` input prop exists, use its onChange, else use local state
    if (this.props.input?.onChange) {
      this.props.input.onChange(value);
    } else {
      this.setState({ selectedContactId: value });
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }
  }

  render() {
    const { loading, selectedContactId } = this.state;
    const { defaultContact, types, input, meta, error, ...custom } = this.props;

    // Check for redux-form error or fallback to provided error prop
    const hasError = (meta?.touched && meta?.error) || error;

    return (
      <SelectFieldWrapper className={hasError ? "has-error" : ""}>
        <Spin spinning={loading}>
          <Select
            {...(input || {})} 
            {...custom}
            value={input?.value || selectedContactId}  // Use input value if present, else local state
            onChange={this.handleSelectChange}  // Handle selection changes
            disabled={defaultContact || !auth0.userHasScope("context:system")}
            className="inputField ant-select-lg"
            placeholder="Company"
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <SelectOption value="">Select Company</SelectOption>
            {this.state.entity.contact && this.getContactListByContext().map((contact, index) => (
              <SelectOption key={index} value={contact.id}>
                {ContactName({ contact })}
              </SelectOption>
            ))}
          </Select>
          {hasError &&
            <Alert
              type="error"
              message={meta?.error || error}
              banner
            />
          }
        </Spin>
      </SelectFieldWrapper>
    );
  }
}

const ContactSelectConnect = connect(
  state => ({
    loading: state.Contact.loading,
    data: state.Contact.data,
  }),
  { listContact, fetchUserDetail }
)(ContactSelect);

export const ContactSelectWFS = ({ name, type, ...custom }) => (
  <FormSection name={name}>
    <Field name="id" type={type} component={ContactSelectConnect} {...custom} />
  </FormSection>
);

export default ContactSelectConnect;

