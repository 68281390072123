import {
  FETCH_SHIFTS_START,
  FETCH_SHIFTS_SUCCESS,
  FETCH_SHIFTS_FAILURE,
  SELECT_SHIFT,
  FETCH_DRIVER,
  FETCH_CONTRACTOR,
  FETCH_VEHICLE,
  FETCH_WORK_REST,
  FETCH_LOCATION_HISTORY,
  SELECT_SHIFT_REQUEST,
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_HISTORY_ERROR,
  UPDATE_ACTIVITY,
  FETCH_REPORT,
} from "./actions";

const initialState = {
  shiftList: [],
  selectedShift: {
    driver: null,
    startDate: null,
    endDate: null,
    vehicle: null,
    contractor: null,
    activities: [],
  },
  loading: false,
  error: null,
  pagination: null,
  drivers: [],
  contractors: [],
  vehicles: [],
  workRest: null,
  mapLoading: false,
  locationHistory: [],
  mapError: false,
  report: null,
};

const shiftReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHIFTS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SHIFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        shiftList: action.payload.results,
        pagination: { total: action.payload.total },
      };
    case FETCH_SHIFTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECT_SHIFT_REQUEST:
      return {
        ...state,
        loading: true,
        selectedShift: [],
      };
    case SELECT_SHIFT:
      return {
        ...state,
        loading: false,
        selectedShift: action.payload,
      };
    case FETCH_DRIVER:
      return {
        ...state,
        drivers: action.payload,
      };
    case FETCH_CONTRACTOR:
      return {
        ...state,
        contractors: action.payload,
      };
    case FETCH_VEHICLE:
      return {
        ...state,
        vehicles: action.payload.results,
      };
    case FETCH_WORK_REST:
      return {
        ...state,
        workRest: action.payload,
      };
    case FETCH_LOCATION_REQUEST:
      return {
        ...state,
        mapLoading: true,
        mapError: false,
        locationHistory: [],
      };

    case FETCH_LOCATION_HISTORY:
      return {
        ...state,
        mapLoading: false,
        mapError: false,
        locationHistory: action.payload,
      };
    case FETCH_LOCATION_HISTORY_ERROR:
      return {
        ...state,
        mapLoading: false,
        mapError: true,
        locationHistory: [],
      };
    case UPDATE_ACTIVITY:
      const activityIndex = state.selectedShift.activities.findIndex((activity) => Number(activity.id) === Number(action.payload?.id));
      let updatedActivities;
      if (activityIndex !== -1) {
        updatedActivities = [...state.selectedShift.activities];
        updatedActivities[activityIndex] = action.payload;
      } else {
        updatedActivities = [...state.selectedShift.activities, action.payload];
      }
      return {
        ...state,
        selectedShift: {
          ...state.selectedShift,
          activities: updatedActivities,
        },
      };
    case FETCH_REPORT:
      return {
        ...state,
        report: action.payload,
      };
    default:
      return state;
  }
};

export default shiftReducer;
