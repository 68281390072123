import asyncComponent from "Common/helpers/AsyncFunc";

const notificationroutes = [

    {
        path: 'control-tower',
        component: asyncComponent(() => import('Notification/containers/ListNotification')),
        scope: 'read:users'
    }
]

export default notificationroutes;