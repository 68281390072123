import { takeEvery, call,put, all, fork} from 'redux-saga/effects';
import actions from 'Driver/redux/actions';
import {push} from 'react-router-redux';
import  Api  from 'Common/api/Api2';

const api = new Api();

export function* createDriver() {
  yield takeEvery(actions.CREATE_DRIVER_REQUEST, function*({data,context,resolve,reject}) {
    try{
      const response = yield call(api.post,'driver',data);
      yield call(resolve,response);
      if(context === 'save'){
      yield(put(push('/drivers/'+response.data.id)));
      }
     }
     catch(error) {
      yield call(reject, error);
    }
  });
}

export function* retrieveDriver() {
  yield takeEvery(actions.RETRIEVE_DRIVER_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.get,'driver/detail/' + id, {});
        yield call(resolve, response);
    }
    catch(error) {
      yield call(reject, error);
    }
  });
}

export function* fetchDriverList(){
  yield takeEvery(actions.FETCH_DRIVER_LIST_REQUEST, function*({data,resolve, reject}){
    try{
      const response = yield call(api.get, "driver/dropdown/all", data);
      if(resolve){
        yield call(resolve, response);
      } else {
        yield put({
          type:actions.FETCH_DRIVER_LIST_SUCCESS,
          response:response.data
        });
      }
    } catch (error){
      if(reject){
        yield call(reject, error);
      } else {
        yield put({
          type:actions.FETCH_DRIVER_LIST_FAIL,
          response:error
        });
      }
    }
  });
}

export function* updateDriver() {
  yield takeEvery(actions.UPDATE_DRIVER_REQUEST, function*({data,id,resolve,reject}) {
    try{
      const response = yield call(api.update,'driver/' + id, data);
      yield call(resolve,response);
      yield put(push('/drivers/' + response.data.id));
    }
    catch(error) {
      yield call(reject, error);
    }
  });
 }

export function* deleteDriver(){
  yield takeEvery(actions.DELETE_DRIVER_REQUEST, function*({id,resolve,reject}){
    try{
      const response = yield call(api.delete,'driver/'+ id, {})
      yield call(resolve,response);
      yield put(push('/drivers'));
    }catch(error){
      yield call(reject,error);
    }
  });
  }

  export function* fetchOrganization() {
    yield takeEvery(actions.FETCH_ORGANIZATION_REQUEST, function*({resolve,reject}) {
      try{
        const response = yield call(api.get, 'organizations', null)
        if(resolve){
          yield call(resolve,response)
        }
      } catch(error) {
        yield call(reject,error)
      }
    });
  }


export function* uploadImage(){
  yield takeEvery(actions.UPLOAD_IMAGE_REQUEST, function*({formData, resolve, reject}){
    try{
      const response = yield call( api.postForm, 'medias', formData);
      yield call(resolve,response);
    } catch(error){
      yield call(reject,error);
    }
  });
}


export function* driverWorkHour() {
  yield takeEvery(actions.FETCH_DRIVER_WORK, function* ({id,startDateTime,period, resolve, reject }) {
    let url = "v1/fatigue/daily?filters[date][start]="+startDateTime+"&filters[days]="+period;
    if (id) {
      url = "v1/fatigue/daily?filters[date][start]="+startDateTime+"&filters[days]="+period+"&filters[driver][id]=" + id;
    }
      try {
      const response = yield call(api.get, url, null)
      if(resolve){
        yield call(resolve,response)
      }
    } catch(error) {
      yield call(reject,error)
    }
  });
}

export function* deleteImage(){
  yield takeEvery(actions.DELETE_IMAGE_REQUEST, function*({id, resolve, reject}){
    try{
      const response = yield call(api.deleteForm, "medias/"+id, {});
      if(resolve){
        yield call(resolve, response);
      }else{
        yield put({
          type:actions.DELETE_IMAGE_SUCCESS,
          response
        })
      }
    }catch(error){
      if(reject){
        yield call(reject, error);
      }else{
        yield put({
          type:actions.DELETE_IMAGE_FAIL,
          error
        })
      }
    }
  });
}

export function* retrieveDriverTraining() {
  yield takeEvery(actions.RETRIEVE_DRIVER_TRAINING_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.get,'driver-training/records/' + id, {});
        yield call(resolve, response);
    }
    catch(error) {
      yield call(reject, error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createDriver),
    fork(retrieveDriver),
    fork(updateDriver),
    fork(deleteDriver),
    fork(fetchOrganization),
    fork(fetchDriverList),
    fork(uploadImage),
    fork(deleteImage),
    fork(driverWorkHour),
    fork(retrieveDriverTraining),
  ]);
}
