import { takeEvery, call, put, all, fork } from 'redux-saga/effects';
import actions from 'Supplier/redux/actions';
import { push } from 'react-router-redux';
import Api from 'Common/api/Api2';

const api = new Api();

export function* createSupplier() {
    yield takeEvery(actions.CREATE_SUPPLIER_REQUEST, function* ({ data, context, resolve, reject }) {
        try {
            const response = yield call(api.post, 'supplier', data);
            yield call(resolve, response);
            if (context === 'save') {
                yield (put(push('/suppliers/' + response.data.id)));
            }
        }
        catch (error) {
            yield call(reject, error);
        }
    });
}

export function* fetchSupplierList() {
    yield takeEvery(actions.FETCH_SUPPLIER_LIST_REQUEST, function* ({ resolve, reject }) {
        try {
            const response = yield call(api.get, "supplier", {});
            if (resolve) {
                yield call(resolve, response);
            } else {
                yield put({
                    type: actions.FETCH_SUPPLIER_LIST_SUCCESS,
                    response: response.data.results
                });
            }
        } catch (error) {
            if (reject) {
                yield call(reject, error);
            } else {
                yield put({
                    type: actions.FETCH_SUPPLIER_LIST_FAIL,
                    response: error
                });
            }
        }
    });
}

export function* retrieveSupplier() {
    yield takeEvery(actions.RETRIEVE_SUPPLIER_REQUEST, function* ({ id, resolve, reject }) {
        try {
            const response = yield call(api.get, 'supplier/' + id, {});
            yield call(resolve, response);
        } catch (error) {
            yield call(reject, error);
        }
    });
}

export function* updateSupplier() {
    yield takeEvery(actions.UPDATE_SUPPLIER_REQUEST, function* ({ data, id, resolve, reject }) {
        try {
            const response = yield call(api.update, 'supplier/' + id, data);
            yield call(resolve, response);
            yield put(push('/suppliers/' + response.data.id));
        } catch (error) {
            yield call(reject, error);
        }
    });
}

export function* deleteSupplier() {
    yield takeEvery(actions.DELETE_SUPPLIER_REQUEST, function* ({ id, resolve, reject }) {
        try {
            const response = yield call(api.delete, 'supplier/' + id, {})
            if (resolve) {
                yield call(resolve, response);
                yield put(push('/suppliers'));
            }
        } catch (error) {
            yield call(reject, error);
        }
    });
}
export default function* rootSaga() {
    yield all([
        fork(createSupplier),
        fork(fetchSupplierList),
        fork(retrieveSupplier),
        fork(updateSupplier),
        fork(deleteSupplier),
    ]);
}
