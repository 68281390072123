import asyncComponent from "Common/helpers/AsyncFunc";

const trainingroutes = [
    {
        path: 'training',
        component: asyncComponent(() => import('Training/containers/ListTraining')),
        scope: 'read:users'
    },
    {
        path: 'training/:id',
        component: asyncComponent(() => import('Training/containers/RetrieveTraining')),
        scope: 'read:users'
    },
]

export default trainingroutes;
