import React, { Component } from "react";
import { connect } from "react-redux";
import { message, Modal,  notification } from "antd";
import moment from "moment";
import Protected from "Common/components/authorization/Protected";
import getProtectedColumn from "Common/components/authorization/VehicleProtectedColumn";
import LayoutContent from "Common/components/layoutContent";
import LayoutWrapper from "Common/components/layoutWrapper.js";
import IntlMessages from "Common/components/intlMessages";
import PageHeader from "Common/components/Page/PageHeader";
import ItemStatus from "Common/components/ItemSummary/status";
import {
  VehicleSection,
  AdminVehicleSection,
} from "Common/components/Table/styles/responsiveTableWrapper.style";
import TableWrapper from "Common/components/Table/index";
import VehicleAdvancedFilterForm from "Vehicle/components/form/VehicleAdvancedFilterForm";
import actions from "Vehicle/redux/actions";
import localizeDate from "Common/localizeDate";
import isMoment from "moment";
import action from "Vehicle/redux/actions";
import actionsDropdown from "Dropdown/redux/actions";
const { listDropdown } = actionsDropdown;

const { fetchUser } = action;

const { deleteVehicle } = actions;

const sorter = (a, b) => ("" + a.attr).localeCompare(b.attr);

const breadcrumbs = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Vehicles",
  },
];

const quickFilter = {
  filterBy: "vehicleType.id",
  defaultValue: "all",
  options: [
    {
      tab: "All Vehicles",
      key: "all",
    },
  ],
};

const getquickFilter = (vehicleTypes, vehicles) => {
  // Define the desired order
  const order = [
    "All Vehicles",
    "Prime Mover",
    "Rigid",
    "Van",
    "(A) Trailer",
    "(B) Trailer",
    "Light Commercial",
    "BDouble Trailer",
    "Other",
  ];

  // Filter the vehicleTypes to include only those present in the vehicles list
  const filteredVehicleTypes = vehicleTypes.filter((type) =>
    vehicles.some(
      (vehicle) => vehicle.vehicleType && vehicle.vehicleType.id === type.id
    )
  );

  // Create the initial quick filter options
  let newQuickFilterOptions = [
    {
      tab: "All Vehicles",
      key: "all",
    },
  ];

  // Add filtered vehicle types to the quick filter options
  newQuickFilterOptions = newQuickFilterOptions.concat(
    filteredVehicleTypes.map((subType) => ({
      tab: subType.title,
      key: subType.id,
    }))
  );

  // Sort the quick filter options based on the predefined order
  const sortedQuickFilterOptions = newQuickFilterOptions.sort((a, b) => {
    const indexA = order.indexOf(a.tab);
    const indexB = order.indexOf(b.tab);

    // Items not in the order array will be pushed to the end
    return (
      (indexA !== -1 ? indexA : order.length) -
      (indexB !== -1 ? indexB : order.length)
    );
  });

  return {
    ...quickFilter,
    options: sortedQuickFilterOptions,
  };
};

class ListVehicle extends Component {
  state = {
    loading: false,
    updatedOn: moment().valueOf(),
    status: "all",
    modalLogin: false,
    visible: false,
    userRole: "",
  };

  setModalLogin(modalLogin) {
    this.setState({ modalLogin });
  }

  componentWillMount = () => {
    this.fetchUser();
    this.setState({
      updatedOn: moment().valueOf(),
      status: this.props.match.params.status,
    });
    if (!this.props.dropdownList.length && !this.props.loading) {
      this.props.listDropdown();
    }
  };

  fetchUser = () => {
    this.setState({ loading: true });
    const userId = this.props.auth.profile.sub;
    const normalizedId = userId.substring(userId.indexOf("|") + 1);
    return new Promise((resolve, reject) => {
      this.props.fetchUser(normalizedId, resolve, reject);
    })
      .then((response) => {
        this.setState({
          loading: false,
          userRole: response.data.role,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      }, this);
  };

  // componentWillReceiveProps = (nextProps) => {
  //   this.setState({
  //     status:nextProps.match.params.status,
  //   })
  //  }

  handleRowClick = (record) => {
    this.props.history.push("/fleet/" + record.id);
  };

  handleConfirm = (record) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: (
        <div className="modal_data_wrapper">
          Are you sure you want to delete <span style={{ fontWeight: "bold" }}>"{record.model}"</span>? <br />
          You will not be able to undo this action.
        </div>
      ),
      okText: "Yes, Delete Vehicle",
      cancelText: "Cancel",
      className: "deleteVehicle",
      onOk: () => {
        this.onDeleteClicked(record.id);
      },
    });
  };

  render() {
    const { userRole } = this.state;
    const StyledVehicleSection =
      userRole === "admin" ? AdminVehicleSection : VehicleSection;
    return (
      <LayoutWrapper>
        <PageHeader title={<IntlMessages id="pagetitle.listVehicle" />} breadcrumbs={breadcrumbs}>
          <Protected scope="write:vehicles">
            <p></p>
          </Protected>
        </PageHeader>
        <LayoutContent loading={this.state.loading}>
          <StyledVehicleSection>
            <TableWrapper
              defaultQuickFilterValue={this.props.match.params.status}
              history={this.props.history}
              handleRowClick={this.handleRowClick}
              updatedOn={this.state.updatedOn}
              columns={this.getDefaultColumns(userRole)}
              url="vehicle"
              advancedFilterForm={VehicleAdvancedFilterForm}
              quickFilterElements={getquickFilter(
                this.props.dropdownList,
                this.props.vehicles
              )}
              quickFilterStatus={this.props.match.params.status}
              createTitle="Add Vehicle"
              onClick={this.onCreateClicked}
              defaultSort={{ field: "vehicleRego", order: "ascend" }}
              tmsTable
            />
          </StyledVehicleSection>
        </LayoutContent>
      </LayoutWrapper>
    );
  }
  onCreateClicked = (e) => {
    e.preventDefault();
    this.props.history.push("/fleet/create");
  };

  onDetailClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/fleet/" + id);
  };

  onDeleteClicked = (id) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.deleteVehicle(id, resolve, reject);
    })
      .then(() => {
        this.setState({ loading: false, updatedOn: moment().valueOf() });
        notification.success({
          className: "notification-wrap",
          message: "Vehicle Deleted Successfully",
          placement: "bottomRight",
          duration: 20,
        });
        // message.success("Successfully deleted fleet");
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error while deleting fleet");
      }, this);
  };

  onEditClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/fleet/" + id + "/edit/");
  };

  confirm = (record) => { 
    this.handleConfirm(record);
  };

  getDefaultColumns = (userRole) => {
    return [
      {
        title: "S.N",
        key: "listNumber",
        render: (text, row, index) => {
          return <span>{index + 1}</span>;
        },
      },
      {
        title: "Vehicle ID",
        key: "vehicleId",
        dataIndex: "vehicleId",
        sorter: { sorter },
      },
      {
        title: "Registration Number",
        key: "vehicleRego",
        dataIndex: "vehicleRego",
      },
      userRole === "admin"
        ? {
            title: "Company",
            key: "company",
            dataIndex: "company",
          }
        : {},
      {
        title: "Fleet Type",
        dataIndex: "vehicleType",
        key: "vehicleType",
      },
      {
        title: "Make",
        dataIndex: "make",
        key: "make",
      },

      {
        title: "Registration Expiry",
        dataIndex: "registrationExpiry",
        key: "registrationExpiry",
        render: (registrationExpiryDate) => {
          let finalDate = "N/A";
          if (!!registrationExpiryDate) {
            if (registrationExpiryDate !== null) {
              finalDate =
                isMoment(localizeDate(registrationExpiryDate)) &&
                localizeDate(registrationExpiryDate).format("DD/MM/YYYY");
            }
          }
          let color = null;
          if (finalDate !== "") {
            const today = moment().startOf("day");
            const targetDate = moment(finalDate, "DD/MM/YYYY");
            if (targetDate.diff(today, "days") <= 0) {
              color = "red"; // Date has passed, set color to red
            } else if (targetDate.diff(today, "days") <= 10) {
              color = "orange"; // 10 or fewer days remaining, set color to orange
            }
          }
          return <span style={{ color }}>{finalDate}</span>;
        },
      },
      {
        title: "Next Service Date",
        dataIndex: "nextServiceDate",
        key: "nextServiceDate",
        render: (text, { nextServiceDetail }) => {
          let finalDate = "N/A";
          if (!!nextServiceDetail) {
            if (nextServiceDetail.nextServiceDate) {
              finalDate = nextServiceDetail.nextServiceDate;
              finalDate =
                isMoment(localizeDate(finalDate)) &&
                localizeDate(finalDate).format("DD/MM/YYYY");
            } else if (
              nextServiceDetail.estimatedNextServiceDate &&
              nextServiceDetail.estimatedNextServiceDate !== "N/A"
            ) {
              let estdDate = nextServiceDetail.estimatedNextServiceDate;
              finalDate =
                isMoment(localizeDate(estdDate)) &&
                localizeDate(estdDate).format("DD/MM/YYYY");
              finalDate = estdDate === "N/A" ? estdDate : finalDate;
            }
          }
          let color = null;
          if (finalDate !== "N/A") {
            const today = moment().startOf("day");
            const targetDate = moment(finalDate, "DD/MM/YYYY");
            if (targetDate.diff(today, "days") <= 0) {
              color = "red"; // Date has passed, set color to red
            } else if (targetDate.diff(today, "days") <= 10) {
              color = "orange"; // 10 or fewer days remaining, set color to orange
            }
          }
          return <span style={{ color }}>{finalDate}</span>;
        },
      },
      {
        title: "Kilometers Till Next Service",
        dataIndex: "nextServiceDetail.dueKms",
        key: "dueKms",
        // render: (dueKms) => (dueKms ? dueKms.toLocaleString() : ""),
        render: (duekms) => {
          if (parseInt(duekms) < 0) {
            return <span style={{ color: "red" }}>{duekms}</span>;
          } else if (parseInt(duekms) < 1000) {
            return <span style={{ color: "orange" }}>{duekms}</span>;
          } else {
            return duekms;
          }
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => ("" + a.attr).localeCompare(b.attr),
        render: (text, row) => <ItemStatus status={row.status} />,
      },
    ];
  };

  getColumns = () => {
    return getProtectedColumn(this.getDefaultColumns, this.onEditClicked, this.confirm, "vehicles");
  };
}

export default connect(
  (state) => ({
    auth: state.Auth,
    dropdownList: state.Dropdown.vehicleType || [],
    vehicles: state.Vehicle.vehicles,
  }),
  {
    deleteVehicle,
    fetchUser,
    listDropdown,
  }
)(ListVehicle);
