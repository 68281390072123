import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { push } from "react-router-redux";

import actions from "Booking/redux/actions";
import Api from "Common/api/Api2";
import Api1 from "Common/api/Api";
const api = new Api();
const api1 = new Api1();

export function* createBooking() {
  yield takeEvery(actions.CREATE_BOOKING_REQUEST, function* ({ data, context, resolve, reject }) {
    try {
      let { departureLocation, arrivalLocation } = data;
      if (!departureLocation.id) {
        departureLocation = {
          ...departureLocation,
          type: "depot",
          name: departureLocation.address,
          geolocation: departureLocation.geolocation ?? '{"lat":0,"lng":0}',
          status: "active",
        };

        const { data: departureData } = yield call(api1.post, "locations", departureLocation);
        data.departureLocation = departureData;
      }
      if (arrivalLocation && !arrivalLocation.id) {
        arrivalLocation = {
          ...arrivalLocation,
          type: "depot",
          name: arrivalLocation.address,
          geolocation: arrivalLocation.geolocation ?? '{"lat":0,"lng":0}',
          status: "active",
        };
        const { data: arrivalData } = yield call(api1.post, "locations", arrivalLocation);
        data.arrivalLocation = arrivalData;
      }
      const response = yield call(api.post, "job-order", data);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}
export function* listJob() {
  yield takeEvery(actions.LIST_BOOKING_REQUEST, function* ({ resolve, reject, query }) {
    try {
      const response = yield call(api.get, `search/job-order${query ? "?" + query : ""}`);
      if (resolve) {
        yield put({
          type: actions.LIST_BOOKING_SUCCESS,
          response: response.data,
        });
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      } else {
        yield put({
          type: actions.LIST_BOOKING_FAILED,
          error,
        });
      }
    }
  });
}

export function* listJobAttachment() {
  yield takeEvery(actions.LIST_JOB_ATTACHMENT_REQUEST, function* ({ driverId, startDateTime, endDateTime, pageSize, currentPage, resolve, reject }) {
    try {
      const response = yield call(
        api.get,
        `report/work-sheet-attachments?driverId=${driverId}&startDate=${startDateTime}&endDate=${endDateTime}&pageSize=${pageSize}&page=${currentPage}`,
        {}
      );
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* updateBooking() {
  yield takeEvery(actions.UPDATE_BOOKING_REQUEST, function* ({ data, id, resolve, reject }) {
    try {
      let { departureLocation, arrivalLocation } = data;
      if (!departureLocation.id) {
        departureLocation = {
          ...departureLocation,
          type: "depot",
          name: departureLocation.address,
          geolocation: departureLocation.geolocation ?? '{"lat":0,"lng":0}',
          status: "active",
        };

        const { data: departureData } = yield call(api1.post, "locations", departureLocation);
        data.departureLocation = departureData;
      }
      if (arrivalLocation && !arrivalLocation.id) {
        arrivalLocation = {
          ...arrivalLocation,
          type: "depot",
          name: arrivalLocation.address,
          geolocation: arrivalLocation.geolocation ?? '{"lat":0,"lng":0}',
          status: "active",
        };
        const { data: arrivalData } = yield call(api1.post, "locations", arrivalLocation);
        data.arrivalLocation = arrivalData;
      }
      const response = yield call(api.update, "job-order/" + id, data);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* deleteBooking() {
  yield takeEvery(actions.DELETE_BOOKING_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api.delete, "job-order/" + id, {});
      if (resolve) {
        yield call(resolve, response);
        yield put(push("/jobs/status/all"));
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* retrieveBooking() {
  yield takeEvery(actions.RETRIEVE_BOOKING_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api.get, "job-order/detail/" + id, {});
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* updateMeterReading() {
  yield takeEvery(actions.UPDATE_METER_READING, function* ({ data, vehicleId, id, resolve, reject }) {
    try {
      const response = yield call(api.update, "vehicles/" + vehicleId + "/meter-readings/" + id, data);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createBooking),
    fork(listJob),
    fork(deleteBooking),
    fork(retrieveBooking),
    fork(updateBooking),
    fork(listJobAttachment),
    fork(updateMeterReading),
  ]);
}
