export default {
  apiUrl: "http://yoursite.com/api/",
};

const siteConfig = {
  siteName: "FCS",
  siteIcon: "ion-flash",
  footerText: "Fleet & Compliance System | 2020-2024 (v10.2.3)",
};
const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault",
};
const language = "english";
export { siteConfig, language, themeConfig };
