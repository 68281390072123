import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import options from "Common/options";
import Scrollbars from "Common/components/customScrollBar.js";
import Menu from "Common/components/menu";
import IntlMessages from "Common/components/intlMessages";
import SidebarWrapper from "App/styles/sidebar.style";
import appActions from "App/redux/actions";
import Logo from "Common/components/logo";
import Auth0 from "Common/helpers/auth0-custom";
import vehicleActions from "Vehicle/redux/actions";

const auth0 = new Auth0();

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;
const ucfirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);
const { fetchVehicleList } = vehicleActions;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
  retrieveMenu,
} = appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOptions: [],
      openKeys: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  componentDidMount() {
    return new Promise((resolve, reject) => {
      this.props.retrieveMenu(resolve, reject);
    })
      .then((response) => {
        this.setState({ dropdownOptions: response.data.results });
      })
      .catch((error) => {
        console.log({ error });
      }, this);
  }
  componentWillMount() {
    if (!this.props.vehicles.length && !this.props.loading) {
      this.props.fetchVehicleList();
    }
  }
  handleClick = (e) => {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  };
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };

  onOpenChange = (openKeys) => {
    this.setState({ openKeys });
  };

  getDocumentIcon = (documentName) => {
    switch (documentName) {
      case "Business":
        return "ri-briefcase-2-line";
      case "Permits":
        return "ri-file-ppt-line";
      case "Accreditation":
        return "ri-check-double-line";
      case "Risk Assessment":
        return "ri-spam-2-fill";
      case "Training":
        return "ri-slideshow-line";
      default:
        return "ri-file-copy-2-line";
    }
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { vehicles } = this.props;
    const { key, label, leftIcon, children, isNew } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (!key || !label) {
      return null; // Skip rendering this item
    }
    const renderNewBadge = () => {
      return isNew ? <span className="new-badge">New</span> : null;
    };

    if (key === "documents") {
      const { dropdownOptions } = this.state;

      // Filter for only "documentsSubTypes"
      const documentSubTypes = dropdownOptions.filter(
        (dropdownOption) => dropdownOption.dropdown === "documentsSubTypes"
      );

      // Return a SubMenu for "Documents" with individual subtypes as SubMenus
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {documentSubTypes
            .filter(
              (dropdownOption) =>
                dropdownOption.title !== "Toolbox Meetings" &&
                dropdownOption.title !== "Job Activities"
            )
            .map((dropdownOption) => {
              let urlLink = `documents/type/${dropdownOption.id}`;
              const linkTo = singleOption.withoutDashboard
                ? `/${urlLink}`
                : `${url}/${urlLink}`;
              return (
                <Menu.Item key={dropdownOption.id}>
                  <Link to={linkTo}>
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i
                        className={this.getDocumentIcon(dropdownOption.title)}
                      />
                      <span className="nav-text">
                        {ucfirst(dropdownOption.title)}
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              );
            })}
        </SubMenu>
      );
    }
    if (key === "fleet") {
      const { dropdownOptions } = this.state;
      const vehicleSubtypes = dropdownOptions.filter(
        (dropdownOption) => dropdownOption.dropdown === "vehicleType"
      );

      // Match dropdown options with the vehicle types in the `vehicles` list
      const filteredVehicleSubtypes = vehicleSubtypes.filter((dropdownOption) =>
        vehicles.some(
          (vehicle) =>
            vehicle.vehicleType && vehicle.vehicleType.id === dropdownOption.id
        )
      );
      // Order vehicle types
      const order = [
        "All",
        "Prime Mover",
        "Rigid",
        "Van",
        "(A) Trailer",
        "(B) Trailer",
        "Light Commercial",
        "BDouble Trailer",
        "Other",
      ];
      const menuItems = [
        {
          id: "all",
          title: "All",
          urlLink: "fleet/status/all",
          isCustom: true,
        },
        ...filteredVehicleSubtypes.map((dropdownOption) => ({
          id: dropdownOption.id,
          title: ucfirst(dropdownOption.title),
          urlLink: `fleet/status/${dropdownOption.id}`,
          isCustom: false,
        })),
      ];

      const sortedMenuItems = menuItems.sort((a, b) => {
        const indexA = order.indexOf(a.title);
        const indexB = order.indexOf(b.title);
        return (
          (indexA !== -1 ? indexA : order.length) -
          (indexB !== -1 ? indexB : order.length)
        );
      });
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {sortedMenuItems.map((item) => {
            const linkTo = singleOption.withoutDashboard
              ? `/${item.urlLink}`
              : `${url}/${item.urlLink}`;
            return (
              <Menu.Item key={item.id}>
                <Link to={linkTo}>
                  <span className="isoMenuHolder" style={submenuColor}>
                    <i className={this.getDocumentIcon(item.title)} />
                    <span className="nav-text">{item.title}</span>
                  </span>
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    if (children && children !== "dynamic") {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {children
            .filter((child) => auth0.userHasScope(child.scope))
            .map((child) => {
              const linkTo = child.withoutDashboard
                ? `/${child.key}`
                : `${url}/${child.key}`;
              return (
                <Menu.Item style={submenuStyle} key={child.key}>
                  <Link style={submenuColor} to={linkTo}>
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className={child.leftIcon} />
                      <span className="nav-text">
                        <IntlMessages id={child.label} />
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              );
            })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <IntlMessages id={label} />
              {renderNewBadge()}
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  render() {
    const { app, toggleOpenDrawer, customizedTheme, height } = this.props;
    const { openKeys } = this.state;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = (event) => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={265}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options
                .filter((singleOption) =>
                  auth0.userHasScope(singleOption.scope)
                )
                .map((singleOption) =>
                  this.getMenuItem({
                    submenuStyle,
                    submenuColor,
                    singleOption,
                  })
                )}
              {/* Demo Menu */}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    app: state.App,
    customizedTheme: "themedefault",
    height: state.App.height,
    vehicles: state.Vehicle.vehicles,
  }),
  {
    toggleOpenDrawer,
    changeOpenKeys,
    changeCurrent,
    toggleCollapsed,
    retrieveMenu,
    fetchVehicleList,
  }
)(Sidebar);
