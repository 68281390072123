import React from 'react';
import {Badge} from 'antd';

const ucfirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const getStatusCode = (status) => {
  switch(status){
    case 'active':
    case 'Active':
    case 'finalized':
    case 'completed':
    case 'pass':
    case 'yes':
    case 'covid negative':
      return 'success';
    case 'commenced':
    case 'Under Investigation':
    case 'in progress':
    case 'In Progress':
    case 'Open':
    case 'open':
    case 'inTransit':
    case 'intransit' :
    case "In Transit":
      return 'warning';
    case 'scheduled':
    case 'published':
      return 'processing'
    case 'inactive':
    case 'Resolved':
    case 'deleted':
    case 'cancelled':
    case 'fail':
    case 'No Futher Action Required':
    case 'no':
    case "covid positive":
      return 'error';
    default:
      return 'default';
  }
}

const getClassName = (status)=>{
  switch(status){
    case 'active':
    case 'Active':
    case 'completed':
    case 'finalized':
      return 'success';
    case 'commenced':
    case 'Under Investigation':
    case 'in progress':
    case 'In Progress':
    case 'Open':
    case 'open':
    case 'inTransit':
    case 'intransit' :
    case "In Transit":
      return 'warning';
    case 'scheduled':
    case 'published':
      return 'processing'
    case 'inactive':
    case 'Resolved':
    case 'deleted':
    case 'cancelled':
    case 'No Futher Action Required':
      return 'error';
    default:
      return 'default';
  }
}

const getStyles = (status) =>{
  switch(status){
    case 'scheduled':
    case 'published':
      return {
        backgroundColor:'#D0DAF2',
        color:'#1548BF'
      }
    default:
      return null

  }

}
const ItemStatus = ({status,label}) => (
  <Badge style={getStyles(status)} className={getClassName(status)} status={getStatusCode(status)} text={label ? ucfirst(label) : ucfirst(status)}/>
)

export default ItemStatus;