import antdEn from "antd/lib/locale-provider/en_US";
import appLocaleData from "react-intl/locale-data/en";
import dashboardMessages from "Dashboard/settings/dashboardlocales.json";
import publicMessages from "Public/settings/publiclocales.json";
import userMessages from "User/settings/userlocales.json";
import locationMessages from "Location/settings/locationlocales.json";
import driverMessages from "Driver/settings/driverlocales.json";
import driverSignupMessages from "DriverSignup/settings/driverSignuplocales.json";
import vehicleMessages from "Vehicle/settings/vehiclelocales.json";
import bookingMessages from "Booking/settings/bookinglocales.json";
import allocationMessages from "Allocation/settings/allocationLocales.json";
import contactMessages from "Contact/settings/contactlocales.json";
import trackingMessages from "Tracking/settings/trackinglocales.json";
import passengerMessages from "Passenger/settings/passengerlocales.json";
import incidentMessages from "Incident/settings/incidentlocales.json";
import feedbackMessages from "Feedback/settings/feedbacklocales.json";
import routeMessages from "Route/settings/routelocales.json";
import runMessages from "Run/settings/runlocales.json";
import faultMessages from "Fault/settings/faultlocales.json";
import fatigueMessages from "Fatigue/settings/fatiguelocales.json";
import documentMessages from "Document/settings/documentlocales.json";
import ncrMessages from "NCR/settings/ncrlocales.json";
import fuelMessages from "Fuel/settings/fuellocales.json";
import supplierMessages from "Supplier/settings/supplierlocales.json";
import maintenanceMessages from "Maintenance/settings/maintenancelocales.json";
import reportMessages from "Report/settings/reportlocales.json";
import assetsMessages from "Assets/settings/assetslocales.json";
import workdiaryMessages from "WorkDiary/settings/workdiarylocales.json";
import assetTypeMessages from "AssetType/settings/assettypelocales.json";
import toolboxMessages from "Toolbox/settings/toolboxlocales.json";
import shiftLocales from "Shifts/settings/shiftLocales.json";
import newsLocales from "NewsTraining/settings/newsLocales.json";
import noticeLocales from "NoticeBoard/settings/noticeLocales.json";
import notificationLocals from "Notification/settings/notificationLocales.json";
import ewdLocales from "EWD/settings/ewdLocales.json";
import trainingLocals from "Training/settings/traininglocals.json"

const EnLang = {
  messages: {
    ...dashboardMessages,
    ...publicMessages,
    ...userMessages,
    ...locationMessages,
    ...driverMessages,
    ...vehicleMessages,
    ...bookingMessages,
    ...contactMessages,
    ...trackingMessages,
    ...allocationMessages,
    ...passengerMessages,
    ...incidentMessages,
    ...feedbackMessages,
    ...routeMessages,
    ...runMessages,
    ...faultMessages,
    ...reportMessages,
    ...fatigueMessages,
    ...documentMessages,
    ...ncrMessages,
    ...fuelMessages,
    ...supplierMessages,
    ...maintenanceMessages,
    ...assetsMessages,
    ...assetTypeMessages,
    ...workdiaryMessages,
    ...driverSignupMessages,
    ...toolboxMessages,
    ...shiftLocales,
    ...newsLocales,
    ...noticeLocales,
    ...notificationLocals,
    ...ewdLocales,
    ...trainingLocals,
  },
  antd: antdEn,
  locale: "en-US",
  data: appLocaleData,
};
export default EnLang;
